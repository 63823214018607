import {Admin, Resource} from "react-admin";

import { BrowserRouter } from 'react-router-dom';

import apiDataProvider from "../providers/dataProvider";

import Dashboard from "./features/Dashboard";
import {TariffgroupCreate} from "./features/tariffgroups/TariffgroupCreate";
import {TariffgroupEdit} from "./features/tariffgroups/TariffgroupEdit";
import {TariffgroupList} from "./features/tariffgroups/TariffgroupList";
import {TarifflinkList} from "./features/tarifflink/TarifflinkList";
import {TarifflinkCreate} from "./features/tarifflink/TarifflinkCreate";
import {TarifflinkShow} from "./features/tarifflink/TarifflinkShow";
import {CdrList} from "./features/cdrs/CdrList";
import {CdrEdit} from "./features/cdrs/CdrEdit";
import {LocationList as ExploitationLocationList} from "./features/exploitation/locations/LocationList";

import authProvider from "../providers/authProvider";
import AppLayout from "./layout/Layout";
import appTheme from "./themes/appTheme";
import {CdrImportJobList} from "./features/cdr-import-jobs/CdrImportJobList";
import {i18nProvider} from "../i18nProvider";
import {LocationEdit as ExploitationLocationEdit} from "./features/exploitation/locations/LocationEdit";
import {CdrIcon, CdrsImportIcon, LocationIcon, TariffgroupIcon, TarifflinkIcon, IssuerIcon} from "./constants";
import {IssuerList} from "./features/facturation/issuers/IssuerList";
import {IssuerShow} from "./features/facturation/issuers/IssuerShow";

const App = () => {
    return (
        <BrowserRouter>
            <Admin layout={AppLayout}
                   theme={appTheme}
                   dataProvider={apiDataProvider}
                   authProvider={authProvider}
                   i18nProvider={i18nProvider}
                   loginPage={false}
                   dashboard={Dashboard} requireAuth>
                <Resource name="tariffgroups" list={TariffgroupList} create={TariffgroupCreate}
                                             edit={TariffgroupEdit} icon={TariffgroupIcon}/>
                <Resource name="tarifflinks" list={TarifflinkList} create={TarifflinkCreate} show={TarifflinkShow} icon={TarifflinkIcon}/>
                <Resource name="cdrs" icon={CdrIcon} list={CdrList} edit={CdrEdit}/>
                <Resource name="cdr-import-jobs" icon={CdrsImportIcon} list={CdrImportJobList} />
                <Resource name="exploitation/locations" icon={LocationIcon} list={ExploitationLocationList}
                          edit={ExploitationLocationEdit} options={{ label: 'Locations Open data' }}/>
                <Resource name="invoicing/issuers" icon={IssuerIcon} list={IssuerList}
                          show={IssuerShow} options={{ label: 'Issuers' }}/>
            </Admin>
        </BrowserRouter>
    );
};

export default App;
