export const colors = {
    e55cLightBlue: '#66a7e5'
}

export { default as CdrIcon } from '@mui/icons-material/Receipt';
export { default as CdrsImportIcon } from '@mui/icons-material/Sync';
export { default as LocationIcon } from "@mui/icons-material/EvStation";
export { default as TariffgroupIcon } from "@mui/icons-material/EuroSymbol";
export { default as TarifflinkIcon } from "@mui/icons-material/Link";
export { default as IssuerIcon } from "@mui/icons-material/Business";
